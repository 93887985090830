<template>
    <section class="check-out-area">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="check-out">
                        <div class="top">
                            <div class="thanks">
                                <span><img src="/images/icon/thanks.png" alt=""></span>
                                Thank you
                            </div>
                            <p>Thank you for choosing All Black Limo!</p>
                            <p>Please ensure to check both your inbox and spam folder for the confirmation email.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style></style>
